import React, { useContext, useState, useEffect } from 'react';
import { PreferencesContext } from 'contexts/preferences';
import { ModalContext } from 'contexts/modal';
import { useRouter } from 'next/router';
import usePaginatedQuery from 'hooks/use-paginated-query';
import SelectField from 'components/control/select-field';
import { sortOptions } from 'utils/select-options';
import { generateRouteMatchObjectFromPath } from 'components/dynamic-page/route-matchers';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import RelatedSearches from 'components/related-searches';
import NamedContent from 'components/named-content';
import Button from 'components/control/button';
import generateHeading from './generate-heading';
import CollapsibleSection from 'components/collapsible-section';
import updateTargetedUrl from 'components/dynamic-page/area-listings-page/update-targeted-url';
import styles from './style.module.scss';
import { useIsMobile } from 'hooks/use-size-class';
import useActive from 'hooks/use-active';
import InternalLinks from 'components/home-page/internal-links';
import Breadcrumbs from 'components/breadcrumbs';
import ListingsGrid from 'components/listings-grid';
import AreaListingsPageFilters from 'components/dynamic-page/area-listings-page/filters';
import Cookies from 'js-cookie';
import { getJSON } from 'utils/cookies';
import HeadData from 'components/head-data';
import buildHeadTags from 'components/dynamic-page/area-listings-page/build-head-tags';
import { testIds, searchFilterIds } from 'constants/test-constants';
import { trackEvent } from 'utils/google-tag-manager';
import { useThemeContext } from 'contexts';
import ConditionalWrapper from 'components/conditional-wrapper';
import LoadWhenVisible from 'components/load-when-visible';
import { genericNamedContentKey, getFooterData } from './get-server-side-props';
import { getListings, sortBy } from 'data/listing';
import { getNamedContents } from 'data/named-content';

import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type Listing from 'data/listing';
import type { IPreferences } from 'contexts/preferences';
import type Address from 'data/addresses';
import type { Breadcrumb } from 'components/breadcrumbs';
import type { PaginationParams } from 'utils/types';
import type { IModalContext } from 'contexts/modal';
import type SearchPrediction from 'data/search-predictions';
import type { LinkDataType } from 'components/home-page/internal-links';
import type { HeadDataType } from 'components/head-data';
import { ThemeNames } from 'types/themes';

interface Props {
  headTags: HeadDataType;
  addresses: Address[];
  breadcrumbs: Breadcrumb[];
  listings: Listing[];
  params: ListingParams & PaginationParams;
  heading: string;
  pageNumber: number;
  totalCount: number;
  totalPages: number;
  genericNamedContent: string[] | null;
  isCrawler: boolean;
  isCrawlerLighthouse: boolean;
  footerData: LinkDataType[];
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

const areaLevels = ['street', 'neighbourhood', 'city', 'province'];
const AreaListingsPage = ({ headTags, addresses, breadcrumbs, listings: originalListings, params: filterParams, heading, pageNumber, totalCount, totalPages, genericNamedContent: initialGenericNamedContent, isCrawler, footerData: initialFooterData }: Props) => {
  
  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { theme, themeName } = useThemeContext();
  const router = useRouter();
  const [pageTitle, setPageTitle] = useState(heading);
  const [filters, setFilters] = useState(filterParams.filter);
  const [updatedHeadData, setUpdateHeadData] = useState<HeadDataType>(headTags);
  const [noListingsInArea, setNoListingsInArea] = useState(false);
  const [hasInitialChanged, setHasInitialChanged] = useState(false);
  const pageParams = { page: { number: pageNumber, size: 28 }};
  const path = (router.query.dynamic as string[]).join('/');
  const routeMatchObject = generateRouteMatchObjectFromPath(path);
  const routeMatchLocationType = routeMatchObject.locationType || routeMatchObject.city && 'city' || routeMatchObject.province && 'province';
  const [activeAreaLevel, setActiveAreaLevel] = useState(areaLevels.indexOf(routeMatchLocationType));
  const [footerData, setFooterData] = useState<LinkDataType[]>(initialFooterData);

  const [PaginationData, setPaginationData] = useState<Pagination>({
    pageNumber: pageNumber,
    pageSize: 24,
    totalCount: totalCount,
    totalPages: totalPages,
  });

  const [listings, setListings] = useState(originalListings);

  const getData = async (params: ListingParams) => {
    if (params.filter.slug === routeMatchObject.filter.slug) {
      setNoListingsInArea(false);
      resetActiveAreaLevel();
    }
    if (params.page) {
      const { listings, meta } = await getListings(params);
      setPaginationData(meta);
      if (!listings.length) {
        setNoListingsInArea(true);
        if (activeAreaLevel === 0) {
          setActiveAreaLevel(routeMatchObject.neighbourhood ? 1 : 2);
        } else {
          setActiveAreaLevel(activeAreaLevel + 1);
        }
      }
      setListings(listings);
    }
  };
  const { params, isLoading, updateParams } = usePaginatedQuery({ sort: filterParams.sort, filter: filters, ...pageParams } as any, getData as any);
  const [isCityGuideExpanded, toggleCityGuide] = useActive(isCrawler);
  const [isInfrastructureExpanded, toggleInfrastructure] = useActive(isCrawler);
  const [isTaxGuideExpanded, toggleTaxGuide] = useActive(isCrawler);
  const isListingsNotAvailablePage = routeMatchObject.filter.status !== 'available' || totalCount === 0;
  const [lazyLoadedFooterLink, setLazyLoadedFooterLink] = useState(false);
  const [lazyLoadedGuide, setLazyLoadedGuide] = useState(false);
  const [genericNamedContent, setGenericNamedContent] = useState(initialGenericNamedContent);
  const guideInfoDefaultExpanded = isCrawler;

  const footerHeading = useIsMobile() ? 'Explore More Listings' : '';

  useEffect(() => {
    if (lazyLoadedFooterLink && !initialFooterData.length) {
      getFooterData({ ...routeMatchObject, locationType: routeMatchLocationType }, filterParams.filter.latitude, filterParams.filter.longitude, themeName).then(r => setFooterData(r || []));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyLoadedFooterLink]);

  useEffect(() => {
    if (lazyLoadedGuide && !genericNamedContent && totalCount > 0) {
      const key = genericNamedContentKey(routeMatchObject);
      getNamedContents({ filter: { key }}).then(genericNamedContents => {
        if (genericNamedContents.length > 0) {
          const namedContent = genericNamedContents?.[0];
          const hasAreaBlurb = namedContent.content.includes('DESCRIPTION BREAK');
          const splitNamedContentData = hasAreaBlurb ? namedContent.content.split('<p>DESCRIPTION BREAK</p>') : namedContent.content;
          setGenericNamedContent(hasAreaBlurb ? splitNamedContentData[1]?.split('<p>SECTION BREAK</p>') : (splitNamedContentData as string).split('<p>SECTION BREAK</p>'));
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyLoadedGuide]);

  useEffect(() => {
    if (hasInitialChanged) {
      getData({ filter: filters, page: params.page, sort: filterParams.sort });
      listingParams.setFilter(filters as ListingParams['filter']);
      updateTargetedUrl(filters as ListingParams['filter'], params.page.number);
      updateHeadData();
      resetActiveAreaLevel();
    }
    setHasInitialChanged(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(filters), ...Object.values(filters.homeType), ...Object.values(filters.additional.condoOrTownhouse), params.page.number]);

  const resetActiveAreaLevel = () =>
    setActiveAreaLevel(areaLevels.indexOf(routeMatchLocationType));

  const updateHeadData = () => {
    const pathName = window.location.pathname.replace(/^\/+/g, '');
    const listingsSortedByPrice = sortBy(listings, listing => listing.price || 0, 'ascending');
    const lowestPrice = listingsSortedByPrice?.[0]?.price;
    const highestPrice = listingsSortedByPrice?.[listingsSortedByPrice.length - 1]?.price;
    const headTags = buildHeadTags(theme, pathName, PaginationData.totalCount, lowestPrice, highestPrice, breadcrumbs, listings as unknown as Listing[], { filter: filters, page: params.page, sort: filterParams.sort });
    setUpdateHeadData(headTags);
  };

  const generateBroaderSlug = () => {
    if (areaLevels[activeAreaLevel] === 'neighbourhood') {
      return routeMatchObject.neighbourhood;
    } else if (areaLevels[activeAreaLevel] === 'city') {
      return routeMatchObject.cityWithProvinceCode;
    } else if (areaLevels[activeAreaLevel] === 'province') {
      return routeMatchObject.province;
    }
    return null;
  };

  const generateNoListingsInAreaCopy = () => {
    const broaderArea = capitalizeWords(deDasherize(routeMatchObject[areaLevels[activeAreaLevel]] || 'Canada'));
    if (routeMatchObject[routeMatchLocationType]) {
      const area = capitalizeWords(deDasherize(routeMatchObject[routeMatchLocationType]));
      return `We couldn't find listings in ${area} but here are some in ${broaderArea}...`;
    }
    return `We couldn't find listings matching the criteria but here are some in ${broaderArea}...`;
  };

  const setNewLocation = (search: SearchPrediction) => {
    search.transitionToPath(listingParams, themeName as ThemeNames, undefined);
  };


  useEffect(() => {
    const updatedAreaLevel = areaLevels.indexOf(routeMatchLocationType) !== activeAreaLevel;
    if (updatedAreaLevel) {
      const broaderSlug = generateBroaderSlug();
      const newParams = { ...params, filter: { ...params.filter, slug: broaderSlug }};
      updateParams(newParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAreaLevel]);

  const onPopState = () => {
    const newFilters = generateRouteMatchObjectFromPath(window.location.pathname);
    const filter = path.includes('/filter');
    if (!filter) {
      setFilters({ ...filters, ...newFilters.filter });
    }
  };

  useEffect(() => {
    trackEvent('UiAreapView');
    window.addEventListener('popstate', onPopState);
    listingParams.setFilter(filters);
    const currentSort = listingParams.sort;
    //Getting sort from cookie
    const listingCookie = Cookies.get('listing-params');
    if (listingCookie) {
      const cookie_param = getJSON(listingCookie) as ListingParams;
      //Setting sort from cookie
      if (currentSort != cookie_param.sort) {
        listingParams.setSort(cookie_param.sort);
        getData({ filter: filters, page: params.page, sort: listingParams.sort });
      }
    }
    // setPositionForArea();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: enable once Apple Maps Service Limits have been increased.
  // const setPositionForArea = () => {
  //   const addressRegion = getMostSpecificAddressRegion(addresses);
  //   if (addressRegion) {
  //     const formattedAddressSearchQuery = getAddressForAppleSearch(addressRegion);
  //     getPositionFromAddress(formattedAddressSearchQuery).then(data => {
  //       if (data) {
  //         const { latitude, longitude } = data.results[0].coordinate;
  //         listingParams.setLatitude(latitude);
  //         listingParams.setLongitude(longitude);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    setPageTitle(generateHeading(listingParams, routeMatchObject, PaginationData.totalCount, areaLevels, activeAreaLevel));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeMatchObject]);

  return (
    <div>
      <HeadData data={updatedHeadData} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={styles.component}>
        <section className={styles['search-nav']}>
          <AreaListingsPageFilters addresses={addresses} locationSearchHandler={setNewLocation} filters={filters} setFilters={setFilters} />
        </section>
        <div className={styles['title-sort-row']}>
          <h1 className={styles['page-title']}>
            {pageTitle}
          </h1>
          <div className={styles['sort-by']}>
            <SelectField
              testId={searchFilterIds.sortByDropdown}
              value={listingParams?.sort}
              options={sortOptions(listingParams.filter.status)}
              onValueChange={sort => {
                listingParams.setSort(sort);
                filterParams.sort = sort;
                updateParams(listingParams);
              }}
            />
            <span className={styles['mobile-filter-btn']}>
              <Button
                testId={searchFilterIds.mobileFilterButton}
                theme="secondary"
                label="Filter"
                onClick={() => openModal('saved-search-filters', { setAreaFilters: setFilters })}
              />
            </span>
          </div>
        </div>
        {noListingsInArea && <p className={styles['no-listings-indicator']}>{generateNoListingsInAreaCopy()}</p>}
        <ListingsGrid
          testId={testIds.listingGrid}
          listings={listings || []}
          isLoading={isLoading}
          params={params}
          listingParams={listingParams}
          meta={{ totalCount: PaginationData.totalCount, totalPages: PaginationData.totalPages }}
          breadcrumbs={breadcrumbs}
          setPageNumber={pageNumber => updateParams({ ...pageParams, page: { number: pageNumber, size: pageParams.page.size }})}
        />
        {!isListingsNotAvailablePage &&
          <ConditionalWrapper
            condition={!guideInfoDefaultExpanded}
            wrapper={children =>
              <LoadWhenVisible onValueChange={()=>setLazyLoadedGuide(true)}>
                {lazyLoadedGuide && children}
              </LoadWhenVisible>
            }>
            <div className={styles['guide-wrapper']} data-testid={testIds.areaGuides}>
              <RelatedSearches routeMatchObject={routeMatchObject} defaultExpanded={isCrawler} areaData={null} surroundingCities={[]} />
              {genericNamedContent && <>
                <CollapsibleSection title="City Guide" isActive={isCityGuideExpanded} onClick={toggleCityGuide} className={styles['area-guide']} testId={testIds.cityGuide}>
                  <NamedContent namedContent={genericNamedContent} type="city-guide" />
                </CollapsibleSection>
                {!!genericNamedContent[1] &&
                <CollapsibleSection title="Infrastructure" isActive={isInfrastructureExpanded} onClick={toggleInfrastructure} className={styles['area-guide']} testId={testIds.infraGuide}>
                  <NamedContent namedContent={genericNamedContent} type="infrastructure" />
                </CollapsibleSection>
                }
                {!!genericNamedContent[2] &&
                <CollapsibleSection title="Tax Guide" isActive={isTaxGuideExpanded} onClick={toggleTaxGuide} className={styles['area-guide']} testId={testIds.taxGuide}>
                  <NamedContent namedContent={genericNamedContent} type="tax-guide" />
                </CollapsibleSection>
                }
              </>}
            </div>
          </ConditionalWrapper>
        }
      </div>
      <section>
        <ConditionalWrapper
          condition={!guideInfoDefaultExpanded}
          wrapper={children =>
            <LoadWhenVisible onValueChange={()=>setLazyLoadedFooterLink(true)}>{lazyLoadedFooterLink && children}</LoadWhenVisible>
          }>
          <InternalLinks isCrawler={isCrawler} data={footerData} heading={footerHeading} />
        </ConditionalWrapper>
      </section>
    </div>
  );
};

export default AreaListingsPage;
