import React, { useContext } from 'react';
import Layout from 'components/layout';
import HeadData from 'components/head-data';
import AreaListingsPage from 'components/dynamic-page/area-listings-page';
import NewAreaListingsPage from 'components/dynamic-page/new-area-listings-page';
import areaListingsPageProps, { AreaListingsPageServerSideProps } from 'components/dynamic-page/area-listings-page/get-server-side-props';
import newAreaListingsPageProps, { AreaListingsPageServerSideProps as NewAreaListingsPageServerSideProps } from 'components/dynamic-page/new-area-listings-page/get-server-side-props';
import EmberPage, { emberPageServerSidePropstype } from 'components/dynamic-page/ember-page';
import createListing from 'data/listing';
import Address from 'data/addresses';
import { FeaturesContext, FeaturesType, type IFeatures } from 'contexts/features';
import { AreaPageListing } from '@zoocasa/go-search';
import { generateRouteMatchObjectFromPath as generateOldRouteMatchObjectFromPath, RouteMatchObject } from 'components/dynamic-page/route-matchers/old-route-matchers';
import { AREA_LISTINGS_ROUTE, EMBER_ROUTE, NOT_FOUND_404_ROUTE } from 'components/dynamic-page/route-matchers';
import { getRouteName } from 'utils/route-name';
import { createGetServerSideProps } from 'utils/shared-getServerSideProps';
import getExpListingByMls from 'utils/get-exp-listing-by-mls';
import { ThemeNames } from 'types/themes';
import { getThemeOverrideFromRequest } from 'utils/themes';
import {
  fetchAgentIdAndDestinationByMLMLSlug,
  fetchMLMLAgentDetailsById,
  postLeadAttribution,
} from 'utils/my-link-my-lead';
import { MLML_LEAD_COOKIE_NAME, MLML_LEAD_EXPIRE_DAYS } from 'constants/cookies';
import { v4 as uuid } from 'uuid';
import configJSON from 'config.json';
import { getStringFromRequestCookie } from 'utils/cookies';
import NotFound404Page from 'components/404';
import { useThemeContext } from 'contexts';
import { AGENT_SEARCH_PATH } from 'utils/agent-endpoint';
import { getSiteLocationFromRequest } from 'utils/site-location';
import { CountryCodeList } from 'types/countries';
import { isTenantExpCA, isTenantExpInService, isTenantExpUS } from 'utils/tenant/which-tenant';

import type { DynamicPageServerSideProps, notFound404PageServerSidePropstype } from 'types/dynamic_page_types';
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { AreaListingsPageViewModel } from 'components/dynamic-page/new-area-listings-page/area_listings_page_view_model';


type serverSidePropsType = DynamicPageServerSideProps<AreaListingsPageServerSideProps | NewAreaListingsPageServerSideProps | emberPageServerSidePropstype | notFound404PageServerSidePropstype>;

// http://localhost:4200/link/mlml/04945c9e
export const getServerSideProps = createGetServerSideProps<GetServerSidePropsResult<serverSidePropsType>>(async (context: GetServerSidePropsContext, features: FeaturesType) => {
  let props: GetServerSidePropsResult<serverSidePropsType>;
  const path = '/' + (context.params?.dynamic as string[]).join('/');
  const fullUrl = context.resolvedUrl;
  const redirectPath = determineRedirectPathForUpdatedAreaPages(path, fullUrl);
  const themeName = getThemeOverrideFromRequest(context.req);

  const storedSiteLocation = getSiteLocationFromRequest(context.req);
  const isUsSite = storedSiteLocation === CountryCodeList.UNITED_STATES;
  const isExpUSTenant = isTenantExpUS(themeName, features);
  const isExpCATenant = isTenantExpCA(themeName, features);
  const isExpInServiceTenant = isTenantExpInService(themeName, features);
  const isExpSite = isExpUSTenant || isExpCATenant || isExpInServiceTenant;

  // Redirect legacy eXp CA and US address pages, rule set in middleware-redirects/index.ts
  const mlsNo = context.query.mlsNo as string;
  const mlsName = context.query.mlsName as string;
  if (mlsNo && isExpSite && path === '/listing') {
    const addressPath = await getExpListingByMls(mlsNo, mlsName);
    if (addressPath) {
      return {
        redirect: {
          permanent: true,
          destination: addressPath,
        },
      };
    } else {
      return {
        redirect: {
          permanent: true,
          destination: '/search',
        },
      };
    }
  }

  // eXp Realty US redirect rules:
  if (isExpUSTenant || isExpInServiceTenant) {
    const mlmlFormat = '/link/mlml/';
    // Needed to match with old legacy URL formats such as /link/firstname_lastname/
    const legacyFormat = /^\/link\/\w+_\w+\//;

    // Extract mlml slug from either url format
    let mlmlSlug = '';
    if (path.startsWith(mlmlFormat)) {
      // Slice off the known prefix '/link/mlml/'
      mlmlSlug = path.slice(mlmlFormat.length);
    } else if (legacyFormat.test(path)) {
      // Remove the matched segment by replacing with ''
      mlmlSlug = path.replace(legacyFormat, '');
    }

    if (mlmlSlug) {
      try {
        const { permanent, agentUuid, destinationUrl } = await fetchAgentIdAndDestinationByMLMLSlug(mlmlSlug);

        const agentInfo = await fetchMLMLAgentDetailsById(agentUuid); // Get agent detail and set browser cookie
        const existingCookie = getStringFromRequestCookie(MLML_LEAD_COOKIE_NAME, context.req);
        let lead_uuid;

        if (existingCookie) {
          const cookieData = JSON.parse(existingCookie);
          lead_uuid = cookieData.lead_uuid || uuid(); // Use lead_uuid from cookie, or generate a new one if not present
        } else {
          lead_uuid = uuid(); // Generate a new lead_uuid if no cookie exists
        }

        const { websiteUUID } = configJSON;
        await postLeadAttribution(lead_uuid, websiteUUID.expRealtyUS, mlmlSlug);
        const cookieData = { ...agentInfo, lead_uuid: lead_uuid, website_uuid: websiteUUID.expRealtyUS };
        context.res.setHeader('Set-Cookie', `${MLML_LEAD_COOKIE_NAME}=${JSON.stringify(cookieData)}; Path=/; Max-Age=${MLML_LEAD_EXPIRE_DAYS * 24 * 60 * 60}; SameSite=Lax`);

        return {
          redirect: {
            permanent,
            destination: destinationUrl,
          },
        };
      } catch (error) {
        console.error('Error fetching agent data:', error);
      }

      return {
        redirect: {
          permanent: true,
          destination: '/search',
        },
      };
    }
  }

  if (context.query.page && isNaN(Number(context.query.page)) || Number(context.query.page) > 200) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  // Redirect old eXp /our-team content page to the new /explore-exp content page
  // Only issue it will redirect without query params
  if (path.startsWith('/our-team')) {
    return {
      redirect: {
        permanent: true,
        destination: path.replace('/our-team', '/explore-exp'),
      },
    };
  } else if (path.startsWith('/new-listings')) { // Deprecating /new-listings page
    if (isUsSite) {
      return {
        redirect: {
          permanent: true,
          destination: '/us-real-estate',
        },
      };
    } else {
      return {
        redirect: {
          permanent: true,
          destination: '/ca-real-estate',
        },
      };
    }
  }

  if (redirectPath) {
    props = {
      redirect: {
        permanent: true,
        destination: redirectPath,
      },
    };
  } else {
    const routeName = getRouteName(path);
    if (routeName === AREA_LISTINGS_ROUTE) {
      if (features?.useNewAreaPage) {
        props = await newAreaListingsPageProps(context);
      } else {
        props = await areaListingsPageProps(context);
      }
    } else if (routeName === EMBER_ROUTE && themeName === ThemeNames.ZOOCASA) {
      props = {
        props: {
          routeName: EMBER_ROUTE,
          props: { headTags: null },
        },
      };
    } else {
      props = {
        props: {
          routeName: NOT_FOUND_404_ROUTE,
          props: { headTags: null },
        },
      };
    }
  }
  return props;
});

export default function DynamicPage({ routeName, props }: serverSidePropsType) {
  return (
    <Layout>
      <DynamicPageContent routeName={routeName} props={props} />
    </Layout>
  );
}

function DynamicPageContent({ routeName, props }: serverSidePropsType) {
  const { features } = useContext(FeaturesContext) as IFeatures;
  const { themeName } = useThemeContext();

  if (routeName === AREA_LISTINGS_ROUTE) {
    if (features.useNewAreaPage) {
      const { viewModel: { listingsData, addresses, fallbackAddress: fallbackAddressData, ...viewModelData }} = props as NewAreaListingsPageServerSideProps;

      const fallbackAddress = fallbackAddressData != null ? fallbackAddressData : null;

      /** We need to convert the listings data from a plain object back into an AreaPageListing Model */
      const listings = listingsData.map((listing: unknown) => AreaPageListing.fromJSON(listing));

      const viewModel: AreaListingsPageViewModel = {
        addresses,
        fallbackAddress,
        ...viewModelData,
        listings,
      };
      return <>
        {props.headTags && <HeadData data={props.headTags} />}
        <NewAreaListingsPage viewModel={viewModel}/>
      </>;
    } else {
      const { headTags, params, heading, pageNumber, totalCount, totalPages, genericNamedContent, isCrawler, isCrawlerLighthouse, footerData, addressesData, listingsData, breadcrumbs } = props as AreaListingsPageServerSideProps;
      const addresses = addressesData.map( i => new Address(i));
      const listings = listingsData.map(i => new createListing(i));
      return <AreaListingsPage headTags={headTags} addresses={addresses} breadcrumbs={breadcrumbs} params={params} listings={listings} heading={heading} pageNumber={pageNumber} totalCount={totalCount} totalPages={totalPages} genericNamedContent={genericNamedContent} isCrawler={isCrawler} isCrawlerLighthouse={isCrawlerLighthouse} footerData={footerData} />;
    }
  } else if (routeName === EMBER_ROUTE && themeName === ThemeNames.ZOOCASA) {
    return <>
      {props.headTags && <HeadData data={props.headTags} />}
      <EmberPage />
    </>;
  } else {
    return <>
      {props.headTags && <HeadData data={props.headTags} />}
      <NotFound404Page />
    </>;
  }
}

function determineRedirectPathForUpdatedAreaPages(path: string, fullUrl: string) {
  const routeMatchObject: RouteMatchObject = generateOldRouteMatchObjectFromPath(path);
  if (isOldCountryFallBackAreaPage(path)) {
    return '/ca-' + fullUrl.slice(1);
  }
  if (routeMatchObject.route === AREA_LISTINGS_ROUTE) {
    const { homeType, city, province, provinceCode, neighbourhood, street, secondaryFilterNeighbourhood, secondaryFilterStreet,
      filter: { rental }} = routeMatchObject;
    const secondaryFilter = routeMatchObject.secondaryFilter || secondaryFilterNeighbourhood || secondaryFilterStreet;
    const secondaryFilterUrl = secondaryFilter ? `/${secondaryFilter}` : '';
    const location = city ? `${city}-${provinceCode}-` : province ? `${province}-` : 'ca-';
    const homeTypeUrl = homeType ? homeType !== 'real-estate' ? `/${homeType}` : '' : '';
    const neighbourhoodUrl = neighbourhood ? `/${neighbourhood}` : '';
    const streetUrl = street ? `/${street}` : '';
    const statusUrl = generateStatusUrl(routeMatchObject);
    const rentUrl = rental ? '/for-rent' : '';

    if (isOldAreaUrlStructure(path)) {
      return `/${location}real-estate${neighbourhoodUrl}${streetUrl}${rentUrl}${statusUrl}${homeTypeUrl}${secondaryFilterUrl}`;
    }
  }
  return null;
}

function isOldAreaUrlStructure(path: string) {
  const urlParts = path.slice(1).split('/');
  const baseLevelAreaPageUrl = `/${urlParts[0]}`;

  // Only look in the base level url pages, like /toronto-on-real-estate-condos. If the string appears in the neighbourhood subdirectory, treat it was a neighbourhood
  if (/-for-rent|-condos|-townhouses|-open-houses|-sold-listings|-past-listings|^\/townhouses|^\/houses|^\/condos|^\/real-estate/i.test(baseLevelAreaPageUrl)) {
    return true;
  }
  if (path.match(/^(?!.*open).*-houses/)) {
    return true;
  }
  return false;
}

function isOldCountryFallBackAreaPage(path: string) { // old: /real-estate; new: /ca-real-estate.
  return path.startsWith('/real-estate');
}

function generateStatusUrl(routeMatchObject: RouteMatchObject) {
  const { listingStatus, filter: { rental }} = routeMatchObject;
  if (listingStatus === 'sold-listings') {
    return rental ? '/leased' : '/sold';
  } else if (listingStatus === 'past-listings') {
    return rental ? '/expired-listings' : '/past-listings';
  }
  return '';
}
