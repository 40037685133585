/* eslint-disable @next/next/no-img-element */
import React from 'react';
import styles from './style.module.scss';
import { searchFilterIds } from 'constants/test-constants';

interface Props {
  label: string;
  imgObj?: {
    id: string;
    image: string;
    alt?: string;
  };
  className?: string;
  isActive: boolean;
  onClick: () => void;
  testId?: string;
}

export default function RadioButton({ label, imgObj, className, isActive, onClick, testId }: Props) {
  if (imgObj) {
    const { id, image, alt } = imgObj;
    return (
      <div className={`${styles.component} ${isActive ? styles.active : ''} ${className}`} onClick={onClick} data-testid={testId} dynamic-testid={(isActive ? searchFilterIds.activeRadioButton : searchFilterIds.inactiveRadioButton)}>
        <img src={image} alt={alt} id={id}/>
        {label}
      </div>
    );
  }

  return (
    <div className={`${styles.component} ${isActive ? styles.active : ''}`} onClick={onClick} data-testid={testId} dynamic-testid={(isActive ? searchFilterIds.activeRadioButton : searchFilterIds.inactiveRadioButton)}>
      {label}
    </div>
  );
}
