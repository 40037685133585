import React from 'react';
import RadioButton from 'components/control/radio-button';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { sanitizeTestid } from 'utils/stringSanitization';

import type { SelectOptionValue, SelectOption } from '@zoocasa/node-kit/components/controls/types';

interface Props<T extends SelectOptionValue> {
  label?: string;
  options: SelectOption<T>[];
  value: T;
  onValueChange: (option: T) => void;
  className?: string;
}

export default function RadioButtonGroup<T extends SelectOptionValue>({ label, options, value, onValueChange, className }: Props<T>) {
  return (
    <div className={buildClassName(styles.component, className)}>
      {label && <label>{label}</label>}
      {options.map(({ value: option, label }, index) => (
        <RadioButton
          key={index}
          label={label}
          isActive={option === value}
          onClick={() => onValueChange(option)}
          testId={sanitizeTestid(label) + testIds.radioButtonOption}
        />
      ))}
    </div>
  );
}
