import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { ButtonGroup as MUIButtonGroup } from '@mui/material';
import Button from 'components/control/button';
import { sanitizeTestid } from 'utils/stringSanitization';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type { SelectOptionValue, SelectOption } from '@zoocasa/node-kit/components/controls/types';

interface Props<T extends SelectOptionValue> {
  options: SelectOption<T>[];
  value: T;
  onValueChange: (option: T) => void;
  className?: string;
  size?: 'small' | 'regular' | 'large';
}

export default function ButtonGroup<T extends SelectOptionValue>({ options, value, onValueChange, className, size = 'regular' }: Props<T>) {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <div className={buildClassName(styles.component, className, isExpTheme && styles['exp-theme'])}>
      <MUIButtonGroup
        aria-label="button group"
        className={buildClassName(
          styles['button-group'],
          size === 'small' && styles['button-group-small'],
          size === 'large' && styles['button-group-large']
        )}
      >
        {options.map(({ value: option, label }, index) => (
          <Button
            key={index}
            className={buildClassName(styles['button-style'], option === value && styles['button-style-selected'])}
            onClick={() => onValueChange(option)}
            type="button"
            label={label}
            testId={sanitizeTestid(label) + 'RadioButtonOption'}
          />
        ))}
      </MUIButtonGroup>
    </div>
  );
}
